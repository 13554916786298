<template>
  <b-modal
    id="add-new-card"
    ref="add-new-card"
    body-class="p-10"
    centered
    title="Adding New Credit Card"
    @ok="addNewCard"
    @show="setModalData"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-new-card"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <div class="credit-card">
        <h2 class="credit-card__title">Credit Card</h2>
        <div class="stripe-card__row stripe-card__row-two-col">
          <div class="stripe-card__number-row">
            <div
              :id="'card-number-' + stripeItemID"
              class="stripe-card__number"
            />
          </div>
          <div
            class="stripe-card__row-right-col"
          >
            <div
              :id="'card-expiry-' + stripeItemID"
              class="stripe-card__date"
            />
            <div
              :id="'card-cvc-' + stripeItemID"
              class="stripe-card__cvc"
            />
          </div>
        </div>
        <div
          :id="'payment-message-' + stripeItemID"
          v-if="hasCardError"
          class="form__error credit-card__error"
        >
          <div v-if="errorNumber">
            {{ errorNumber }}
          </div>
          <div v-if="errorCvc">
            {{ errorCvc }}
          </div>
          <div v-if="errorExpiry">
            {{ errorExpiry }}
          </div>
        </div>
        <div
          :id="'card-error-' + stripeItemID"
          class="form__error credit-card__error"
        />
      </div>
    </b-overlay>
    <template #modal-footer="{ cancel, ok }">
      <div class="w-100 d-flex justify-content-between">
        <b-button
          class="border border-primary"
          variant="outline-primary" 
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import { BModal, BButton, BOverlay } from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js'
import uniqueId from 'lodash/uniqueId'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,

  },
  data() {
    return {
      userId: getUserData().id,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      errorNumber: null,
      errorExpiry: null,
      errorCvc: null,
      stripeItemID: '',
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'paymentPortal/getIsModalLoaidng',
      publishableKey: 'paymentSettings/getStripePublishKey',
    }),
    hasCardError() {
      return !!this.errorNumber || !!this.errorExpiry || !!this.errorCvc
    },
  },
  beforeDestroy () {
    if (this.cardNumber) {
      this.cardNumber.destroy()
    }
    if (this.cardExpiry) {
      this.cardExpiry.destroy()
    }
    if (this.cardCvc) {
      this.cardCvc.destroy()
    }
  },
  methods: {
    async setModalData() {
      this.stripeItemID = uniqueId()

      // Load Stripe.js with your publishable key
      this.stripe = await loadStripe(this.publishableKey)
      const elements = this.stripe.elements()

      const style = {
        style: {
          base: {
            fontSize: '16px',
            color: process.env.SECONDARY_COLOR,
            backgroundColor: '#EDEDED',
            '::placeholder': {
              color: process.env.SECONDARY_COLOR,
            },
          },
        },
      }

      // Create and mount the card number input field
      this.cardNumber = elements.create('cardNumber', style)
      this.cardNumber.mount('#card-number-' + this.stripeItemID)
      this.cardNumber.addEventListener('change', (event) => {
        if (event.error) {
          this.errorNumber = event.error.message
        } else {
          this.errorNumber = null
        }
      })


      this.cardExpiry = elements.create('cardExpiry', style)
      this.cardExpiry.mount('#card-expiry-' + this.stripeItemID)
      this.cardExpiry.addEventListener('change', (event) => {
        if (event.error) {
          this.errorExpiry = event.error.message
        } else {
          this.errorExpiry = null
        }
      })

      this.cardCvc = elements.create('cardCvc', style)
      this.cardCvc.mount('#card-cvc-' + this.stripeItemID)
      this.cardCvc.addEventListener('change', (event) => {
        if (event.error) {
          this.errorCvc = event.error.message
        } else {
          this.errorCvc = null
        }
      })
    },
    async createToken() {
      const { token, error } = await this.stripe.createToken(this.cardNumber)
      if (error) {
        // handle error here
        document.getElementById('#' + this.stripeItemID + 'card-error').innerHTML = error.message
        return
      }

      return token
    },
    async addNewCard(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.hasCardError) {
        return
      }

      const token = await this.createToken()

      token.user_id = this.userId

      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await store.dispatch('paymentPortal/createNewCreditCard', token)
      await store.dispatch('paymentPortal/fetchCreditCardsList') 
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
      this.$refs['add-new-card'].hide()
    },

    resetModal() {
      if (this.cardNumber) {
        this.cardNumber.destroy()
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy()
      }
      if (this.cardCvc) {
        this.cardCvc.destroy()
      }
    },
  }
}
</script>

<style>

</style>