<template>
  <b-tab 
    title="Transactions View" 
    title-item-class="payment-portal__tab"
    active
    lazy
  >
  <!-- Loader -->
    <template v-if="isBusy">
      <div class="d-flex justify-content-center">
        <b-spinner 
          class="align-middle"
        />
      </div>
    </template>

    <app-collapse
      v-else
      v-for="(transactionsPerApplication, key) in transactionsTableItems"
      :key="key"
      class="mb-3" 
    >
      <app-collapse-item
        title
        :is-visible="false"
      >
        <template #header>
          <h5
            class="d-flex align-items-center mb-0"
          >
            {{transactionsPerApplication[0].application_user_name}} - {{transactionsPerApplication[0].program_name}}
            <b-badge
              variant="light"
              class="text-muted ml-3 mr-2 p-2"
            >
              <span class="font-weight-normal">Remaining balance:</span> <b class="font-weight-bold">{{transactionsPerApplication[0].remaining_total | fixAmount}}</b>
            </b-badge>
          </h5>
        </template>

        <div class="overflow-auto">
          <b-table
            ref="refTransactionsTable"
            :items="transactionsPerApplication"
            :fields="tableColumns"
            :busy="isBusy"
            thead-class="table-header"
            :tbody-tr-class="rowClass"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="position-relative w-100"
          >

            <!-- Loader -->
            <template #table-busy>
              <div class="d-flex justify-content-center">
                <b-spinner 
                  class="align-middle"
                />
              </div>
            </template>

            <!-- Column: # -->
            <template #cell(#)="data">
              <div
                v-if="data.item.paidFromTransactions.length > 0"
                class="table-more-details"
                :class="{'open': data.detailsShowing}"
                @click="data.toggleDetails"
              >
              </div>
              <div
                v-else
                class="table-more-details disabled"
              >
              </div>
            </template>

            <!-- Column: Id -->
            <template #cell(id)="data">
              <span :id="data.item.id">{{ data.item.id | shortId }}</span>
              <b-tooltip
                triggers="hover"
                :target="data.item.id"
                :delay="{ show: 100, hide: 50 }"
              >
                {{ data.item.id }}
              </b-tooltip>
            </template>

            <!-- Column: Date -->
            <template #cell(created_at)="data">
              <span :id="data.item.created_at + data.item.id">{{ data.item.created_at | shortDate }}</span>
              <b-tooltip
                triggers="hover"
                :target="data.item.created_at + data.item.id"
                :delay="{ show: 100, hide: 50 }"
              >
                {{ data.item.created_at | longDate }}
              </b-tooltip>
            </template>

            <!-- Column: student -->
            <template #cell(student)="data">
              {{ data.item.user.full_name }}
            </template>

            <!-- Column: model_type -->
            <template #cell(model_type)="data">
              {{ data.item.model_type == 'Charge' ? 'Invoice' : data.item.model_type }}
            </template>

            <!-- Column: amount -->
            <template #cell(amount)="data">
              {{ data.value | fixAmount }}
            </template>


            <!-- Column: type -->
            <template #cell(type)="data">
              {{ data.item.type == 'Charge' ? 'Invoice' : data.item.type }}
            </template>

            <!-- Column: status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveStatusVariant(data.value)}`"
              >
                {{ data.value }}
              </b-badge>
            </template>

          </b-table>
        </div>
      </app-collapse-item>
    </app-collapse>
  </b-tab>
</template>

<script>
import { mapGetters } from 'vuex'
import store from "@/store"

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { 
  BTab, BTable, BTooltip, BBadge, BSpinner, 
} from 'bootstrap-vue'

import { filters } from '@core/mixins/filters'

export default {
  name: "TransactionsViewTab",
  components: {
    AppCollapse,
    AppCollapseItem,
    BTab,
    BTable,
    BTooltip,
    BBadge,
    BSpinner,
  },
  mixins: [ filters ],
  data() {
    return {
      tableColumns: [
        { key: 'id', label: 'Id' },
        { key: 'created_at', label: 'Date' },
        { key: 'model_type', label: 'Type' },
        { key: 'amount', label: 'Amount' },
        { key: 'description', label: 'Description' },
        { key: 'type', label: 'Payment method' },
        { key: 'status', label: 'Status' },
      ],
    }
  },
  async created() {
    await store.dispatch('paymentPortal/fetchTransactionsList')
  },
  computed: {
    ...mapGetters({
      isBusy: 'paymentPortal/getIsTransactionsTableBusy',
      transactionsTableItems: 'paymentPortal/getTransactionsTableItems',
    }),
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status.toLowerCase() === 'payment failed') return 'text-primary'
      if (item.status.toLowerCase() === 'payment failed (retried)') return 'text-primary'
      if (item.model_type === 'Charge') return 'text-danger'
      if (item.model_type === 'Payment') return 'text-success'
    },
    resolveStatusVariant(status) {
      if (status.toLowerCase() === 'completed') return 'success'
      if (status.toLowerCase() === 'applied') return 'success'
      if (status.toLowerCase() === 'payment failed') return 'primary'
      if (status.toLowerCase() === 'payment failed (retried)') return 'primary'
      if (status.toLowerCase() === 'pending') return 'warning'
      if (status.toLowerCase() === 'canceled') return 'pink'
      if (status.toLowerCase() === 'manual cancel') return 'warning'
      if (status.toLowerCase() === 'refunded') return 'purple'
      return 'primary'
    },
  },
}
</script>

<style>

</style>